<script setup lang="ts">
import { getDefaultAvatarPath } from '~/libs/getDefaultAvatarPath'

const props = defineProps<{
  imgStr: string,
  widthClass: string,
  heightClass: string,
  roundSize: 'small'|'medium'|'large'|'none'
}>();

const onerrorStr = "this.onerror=null;this.src=\"" + getDefaultAvatarPath('') + "\";"

let imgRounded = [
  props.heightClass,
  props.widthClass
]

switch(props.roundSize) {
  case 'small':
    imgRounded = imgRounded.concat('rounded')
    break;
  case 'medium':
    imgRounded = imgRounded.concat('rounded-md')
    break;
  case 'large':
    imgRounded = imgRounded.concat('rounded-lg')
    break;
  case 'none':
    imgRounded = imgRounded.concat('rounded-none')
    break;
  default:
    imgRounded = imgRounded.concat('rounded')
}
</script>

<template>
  <div
    :class="[
      props.heightClass,
      props.widthClass,
    ]"
  >
    <img
      alt="avatar"
      :src=props.imgStr
      :class="imgRounded"
      :onerror="onerrorStr"
      class="
        object-cover
      "
    >
  </div>
</template>
